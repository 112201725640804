
(function(){
   'use strict';
	
   angular
   .module('vdsr')
   .filter('toDate', [function() {
      return filter_;
   }])
   
   /**
    * Converts given seconds to date by assuming seconds is the time passed since epoch
    * @param {string} secondsInput 
    */
   function filter_(secondsInput) {
      let seconds = Number(secondsInput) || 0;
      return new Date(seconds * 1000);
   }
   
   
}());


